
.editor[data-v-351fcda4] {
  position: relative;
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bottom-container[data-v-351fcda4] {
  position: relative;
}
