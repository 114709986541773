
#navigator[data-v-a291e35a] {
  width: 200px;
  /* height: 176px; */
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 3;
}
.pannel-title[data-v-a291e35a] {
  height: 32px;
  border-top: 1px solid #dce3e8;
  border-bottom: 1px solid #dce3e8;
  background: #ebeef2;
  color: #000;
  line-height: 28px;
  padding-left: 12px;
}
